import Link from 'next/link';
import { useLocationState } from '../context/location';
import { useObjectsDispatch } from '../context/objects';

const Footer = ({ seo }) => {
  const locationState = useLocationState();
  const setObjectsState = useObjectsDispatch();

  return (
    <footer>
      <section className="footer">
        <div className="top">
          <Link className="logo" href="/" passHref>
            <svg
              viewBox="0 0 389 41"
              width="100%"
              height="100%"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 39.1495H12.8324L23.2736 11.1628H26.7009H31.9614V39.1495H45.272V0.877115L13.7091 0.797363L0 39.1495Z" />
              <path d="M50.2933 20.0134C50.2933 25.9935 50.1339 32.3722 49.8948 39.1496H90.9425V29.5018H62.4084V27.349V26.4719V24.0001C64.0821 24.0001 66.6327 23.9204 69.9006 23.9204C72.1323 23.9204 74.4437 23.9204 76.7551 24.0001H87.1964V15.4685H76.7551C74.4437 15.4685 72.1323 15.5483 69.9006 15.5483C66.553 15.5483 64.0821 15.5483 62.4084 15.4685V12.5981V10.4453H90.9425V0.877197H49.8948C50.1339 7.65461 50.2933 14.0333 50.2933 20.0134Z" />
              <path d="M127.846 39.1495H140.279C140.04 33.1695 139.881 26.7907 139.881 20.0133C139.881 13.1562 140.04 6.77743 140.279 0.797363H127.846V15.1495H108.398V0.797363H95.9639C96.2827 7.65451 96.3624 14.0332 96.3624 20.0133C96.3624 25.9934 96.203 32.3721 95.9639 39.1495H108.398V24.7974H127.846V39.1495Z" />
              <path d="M189.377 39.1495H192.326V28.8638H189.377V0.877115L157.814 0.797363L147.692 28.8638H144.025V39.1495H156.938H176.067H189.377ZM167.299 11.1628H170.806H176.067V28.9435H160.763L167.299 11.1628Z" />
              <path d="M228.193 39.4684H241.504V1.19593L209.941 1.11621L196.152 39.4684H209.064L219.426 11.4817H222.933H228.193V39.4684Z" />
              <path d="M269.48 40.0266C277.61 40.0266 283.588 38.2724 287.413 34.7641C291.159 31.2558 293.072 26.3123 293.072 19.9336C293.072 13.6346 291.159 8.77076 287.413 5.26246C283.667 1.75415 277.689 0 269.56 0C261.43 0 255.532 1.75415 251.706 5.26246C247.96 8.77076 246.047 13.7143 246.047 19.9336C246.047 26.3123 247.96 31.1761 251.786 34.6844C255.452 38.2724 261.43 40.0266 269.48 40.0266ZM261.031 13.0764C262.785 11.5615 265.574 10.8439 269.4 10.8439C273.306 10.8439 276.095 11.5615 277.849 13.0764C279.602 14.5914 280.399 16.9036 280.399 20.0133C280.399 23.2026 279.523 25.5149 277.849 27.0299C276.095 28.5448 273.306 29.2624 269.4 29.2624C265.495 29.2624 262.705 28.5448 261.031 27.0299C259.278 25.5149 258.481 23.2027 258.481 20.093C258.481 16.9037 259.357 14.5914 261.031 13.0764Z" />
              <path d="M335.873 4.94345C333.164 2.47169 328.222 1.19593 321.208 1.19593H310.209V1.11621H297.217C297.536 7.97335 297.615 14.3521 297.615 20.2524C297.615 26.2325 297.456 32.6112 297.217 39.4684H310.209V30.1395H321.208C326.07 30.1395 329.816 29.5016 332.526 28.3056C335.236 27.1096 337.228 25.4352 338.265 23.2026C339.38 21.0498 339.938 18.4185 339.938 15.3089C339.938 10.9235 338.583 7.41521 335.873 4.94345ZM327.186 18.4185C326.628 19.0564 325.592 19.4551 324.157 19.6943C322.722 19.9335 320.491 20.0132 317.621 20.0132H310.368V11.6411H317.621C320.65 11.6411 322.802 11.7209 324.316 11.9601C325.751 12.1993 326.787 12.5979 327.345 13.1561C327.903 13.7142 328.142 14.5913 328.142 15.7873C328.142 16.9833 327.744 17.7807 327.186 18.4185Z" />
              <path d="M385.529 29.2624V1.19593L353.966 1.11621L343.844 29.1827H340.177V39.4684H353.09H372.219H385.609H388.558V29.1827H385.529V29.2624ZM372.219 29.2624H356.915L363.531 11.4817H367.038H372.298V29.2624H372.219Z" />
            </svg>
          </Link>
          <div className="socials">
            {/* <a
              href="https://www.instagram.com/landlord_rostov/"
              className="instagram no-animation"
              title="Instagram"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://www.facebook.com/landlord.ru/"
              className="facebook no-animation"
              title="Facebook"
              target="_blank"
              rel="noreferrer"
            /> */}
            <a
              href="https://vk.com/landlord"
              className="vkontakte no-animation"
              title="ВКонтакте"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://t.me/landlordrostov"
              className="telegram no-animation"
              title="Telegram"
              target="_blank"
              rel="noreferrer"
            />
            <a
              href="https://ok.ru/group/59506265817271"
              className="odnoklassniki no-animation"
              title="Одноклассники"
              target="_blank"
              rel="noreferrer"
            />
          </div>
          <div className="phone">
            <a className="number" href={locationState.phoneURI}>
              {locationState.phone}
            </a>
            <div className="time">9:00 — 19:00 МСК</div>
          </div>
        </div>
        <ul className="middle">
          <li>
            <Link
              href="/objects/buy/flats/1k"
              tabIndex={0}
              onClick={() =>
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: { type: 'продажа', category: 'квартира', rooms: ['1'] }
                })
              }
            >
              Однокомнатные квартиры
            </Link>
          </li>
          <li>
            <Link
              href="/objects/buy/flats/2k"
              passHref
              role="button"
              tabIndex={0}
              onClick={() =>
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: { type: 'продажа', category: 'квартира', rooms: ['2'] }
                })
              }
            >
              Двухкомнатные квартиры
            </Link>
          </li>
          <li>
            <Link
              href="/objects/buy/flats/3k"
              passHref
              role="button"
              tabIndex={0}
              onClick={() =>
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: { type: 'продажа', category: 'квартира', rooms: ['3'] }
                })
              }
            >
              Трехкомнатные квартиры
            </Link>
          </li>
          <li>
            <Link
              href="/objects/buy/houses"
              passHref
              role="button"
              tabIndex={0}
              onClick={() =>
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: { type: 'продажа', category: 'дом' }
                })
              }
            >
              Домовладения
            </Link>
          </li>
          <li>
            <Link
              href="/objects/buy/sites"
              passHref
              role="button"
              tabIndex={0}
              onClick={() =>
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: { type: 'продажа', category: 'участок' }
                })
              }
            >
              Земельные участки
            </Link>
          </li>
          <li>
            <Link
              href="/objects/buy/commercial"
              passHref
              role="button"
              tabIndex={0}
              onClick={() =>
                setObjectsState({
                  type: 'SET_FILTER',
                  payload: { type: 'продажа', category: 'коммерция' }
                })
              }
            >
              Коммерческая недвижимость
            </Link>
          </li>
          <li>
            <Link href="/mortgage" passHref>
              Ипотека
            </Link>
          </li>
          <li>
            <Link href="/services" passHref>
              Услуги
            </Link>
          </li>
          <li>
            <Link href="/contacts" passHref>
              Контакты
            </Link>
          </li>
          <li>
            <Link href="/career" passHref>
              Карьера
            </Link>
          </li>
          <li>
            <Link href="/about" passHref>
              О нас
            </Link>
          </li>
        </ul>
        {seo && (
          <div className="seo">
            <h1>{seo}</h1>
          </div>
        )}
        <div className="apps">
          <a href="https://apps.apple.com/ru/app/id1658493916" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="40"
              viewBox="0 0 120 40"
              fill="none"
            >
              <path
                d="M110.135 0.00013H9.53468C9.16798 0.00013 8.80568 0.00013 8.43995 0.00213C8.1338 0.00413 7.83009 0.00994 7.521 0.01483C6.84951 0.0227318 6.17961 0.0818063 5.5171 0.19154C4.85552 0.303663 4.21467 0.515046 3.61622 0.81854C3.0185 1.1246 2.47235 1.5223 1.99757 1.9972C1.5203 2.47077 1.12246 3.01815 0.81935 3.61829C0.5154 4.21724 0.304641 4.85907 0.19435 5.52161C0.0830109 6.18332 0.0230984 6.85265 0.01515 7.52361C0.00587 7.83021 0.00489 8.13783 0 8.44447V31.5587C0.00489 31.8692 0.00587 32.17 0.01515 32.4806C0.0231008 33.1516 0.0830134 33.8209 0.19435 34.4825C0.304336 35.1455 0.515108 35.7877 0.81935 36.3868C1.12233 36.985 1.52022 37.5302 1.99757 38.0011C2.47054 38.4781 3.01705 38.8761 3.61622 39.1798C4.21467 39.4841 4.85545 39.6968 5.5171 39.8106C6.17972 39.9195 6.84956 39.9786 7.521 39.9874C7.83009 39.9942 8.1338 39.9981 8.43995 39.9981C8.80567 40.0001 9.168 40.0001 9.53468 40.0001H110.135C110.494 40.0001 110.859 40.0001 111.219 39.9981C111.523 39.9981 111.836 39.9942 112.141 39.9874C112.811 39.9791 113.479 39.92 114.141 39.8106C114.804 39.696 115.448 39.4834 116.049 39.1798C116.647 38.8759 117.193 38.478 117.666 38.0011C118.142 37.5284 118.541 36.9836 118.848 36.3868C119.15 35.7872 119.358 35.1451 119.467 34.4825C119.578 33.8208 119.64 33.1516 119.652 32.4806C119.656 32.17 119.656 31.8692 119.656 31.5587C119.664 31.1954 119.664 30.8341 119.664 30.4649V9.53626C119.664 9.17005 119.664 8.80677 119.656 8.44447C119.656 8.13783 119.656 7.83021 119.652 7.52357C119.64 6.85255 119.578 6.18337 119.467 5.52157C119.358 4.85941 119.149 4.21763 118.848 3.61825C118.23 2.41533 117.252 1.43616 116.049 0.81845C115.448 0.515697 114.804 0.30437 114.141 0.19145C113.48 0.0812328 112.811 0.0221378 112.141 0.01469C111.836 0.00981 111.523 0.00395 111.219 0.002C110.859 0 110.494 0.00013 110.135 0.00013Z"
                fill="#A6A6A6"
              />
              <path
                d="M8.44484 39.125C8.14016 39.125 7.84284 39.1211 7.54055 39.1143C6.91433 39.1061 6.28957 39.0516 5.67141 38.9512C5.095 38.8519 4.53661 38.6673 4.01467 38.4033C3.49751 38.1415 3.02582 37.7983 2.61767 37.3867C2.20361 36.98 1.85888 36.5082 1.59716 35.9902C1.33255 35.4688 1.14942 34.9099 1.05416 34.333C0.951281 33.7131 0.895621 33.0863 0.887656 32.458C0.881316 32.2471 0.873016 31.5449 0.873016 31.5449V8.44434C0.873016 8.44434 0.881856 7.75293 0.887706 7.5498C0.895332 6.92248 0.950669 6.29665 1.05324 5.67773C1.14868 5.09925 1.33194 4.53875 1.5967 4.01563C1.85746 3.49794 2.20027 3.02586 2.61184 2.61768C3.02294 2.20562 3.49614 1.8606 4.01418 1.59521C4.53492 1.33209 5.09225 1.14873 5.6675 1.05127C6.28769 0.949836 6.91462 0.894996 7.54301 0.88721L8.44533 0.875H111.214L112.127 0.8877C112.75 0.895099 113.371 0.94945 113.985 1.05029C114.566 1.14898 115.13 1.33362 115.656 1.59814C116.694 2.13299 117.539 2.97916 118.071 4.01807C118.332 4.53758 118.512 5.09351 118.606 5.66699C118.71 6.29099 118.768 6.92174 118.78 7.5542C118.783 7.8374 118.783 8.1416 118.783 8.44434C118.791 8.81934 118.791 9.17627 118.791 9.53613V30.4648C118.791 30.8281 118.791 31.1826 118.783 31.54C118.783 31.8652 118.783 32.1631 118.779 32.4697C118.768 33.0909 118.71 33.7104 118.608 34.3232C118.515 34.9043 118.333 35.4675 118.068 35.9932C117.805 36.5056 117.462 36.9733 117.053 37.3789C116.644 37.7927 116.172 38.1379 115.653 38.4014C115.128 38.6674 114.566 38.8527 113.985 38.9512C113.367 39.0522 112.742 39.1067 112.116 39.1143C111.823 39.1211 111.517 39.125 111.219 39.125L110.135 39.127L8.44484 39.125Z"
                fill="black"
              />
              <path
                d="M24.7689 20.3007C24.7796 19.466 25.0013 18.6476 25.4134 17.9217C25.8254 17.1957 26.4143 16.5858 27.1254 16.1486C26.6737 15.5035 26.0777 14.9725 25.3849 14.598C24.6921 14.2234 23.9215 14.0156 23.1343 13.9909C21.455 13.8147 19.8271 14.9958 18.9714 14.9958C18.0991 14.9958 16.7816 14.0084 15.3629 14.0376C14.4452 14.0673 13.5509 14.3341 12.767 14.8122C11.9831 15.2902 11.3364 15.9632 10.89 16.7655C8.95595 20.1139 10.3985 25.035 12.2511 27.7416C13.178 29.0669 14.2613 30.5474 15.6788 30.4949C17.0658 30.4374 17.5839 29.6104 19.2582 29.6104C20.9169 29.6104 21.403 30.4949 22.8492 30.4615C24.3376 30.4373 25.2753 29.1303 26.1697 27.7924C26.8357 26.848 27.3481 25.8043 27.6881 24.6999C26.8234 24.3341 26.0855 23.7219 25.5664 22.9396C25.0472 22.1574 24.7699 21.2395 24.7689 20.3007Z"
                fill="white"
              />
              <path
                d="M22.0372 12.2109C22.8488 11.2367 23.2486 9.98451 23.1518 8.72028C21.9119 8.8505 20.7667 9.44306 19.9442 10.3799C19.542 10.8376 19.234 11.37 19.0378 11.9468C18.8415 12.5235 18.7609 13.1333 18.8005 13.7413C19.4206 13.7477 20.0341 13.6132 20.5948 13.3482C21.1554 13.0831 21.6486 12.6942 22.0372 12.2109Z"
                fill="white"
              />
              <path
                d="M42.3022 27.1397H37.5688L36.4321 30.4961H34.4272L38.9106 18.0781H40.9936L45.477 30.4961H43.438L42.3022 27.1397ZM38.0591 25.5908H41.8111L39.9615 20.1436H39.9097L38.0591 25.5908Z"
                fill="white"
              />
              <path
                d="M55.1597 25.9697C55.1597 28.7832 53.6538 30.5908 51.3813 30.5908C50.8057 30.6209 50.2332 30.4883 49.7294 30.2082C49.2256 29.9281 48.8109 29.5117 48.5327 29.0068H48.4897V33.4912H46.6313V21.4424H48.4302V22.9483H48.4644C48.7553 22.4458 49.177 22.0316 49.6847 21.7498C50.1923 21.4679 50.7668 21.3289 51.3472 21.3477C53.645 21.3477 55.1597 23.1641 55.1597 25.9697ZM53.2495 25.9697C53.2495 24.1367 52.3022 22.9317 50.8569 22.9317C49.437 22.9317 48.4819 24.1621 48.4819 25.9697C48.4819 27.794 49.437 29.0156 50.8569 29.0156C52.3022 29.0156 53.2495 27.8194 53.2495 25.9697Z"
                fill="white"
              />
              <path
                d="M65.1245 25.9697C65.1245 28.7832 63.6187 30.5908 61.3462 30.5908C60.7705 30.6209 60.198 30.4883 59.6942 30.2082C59.1905 29.9281 58.7757 29.5117 58.4976 29.0068H58.4546V33.4912H56.5962V21.4424H58.395V22.9482H58.4292C58.7201 22.4458 59.1418 22.0316 59.6495 21.7497C60.1571 21.4679 60.7317 21.3289 61.312 21.3477C63.6099 21.3477 65.1245 23.1641 65.1245 25.9697ZM63.2144 25.9697C63.2144 24.1367 62.2671 22.9316 60.8218 22.9316C59.4018 22.9316 58.4468 24.1621 58.4468 25.9697C58.4468 27.794 59.4018 29.0156 60.8218 29.0156C62.2671 29.0156 63.2144 27.8193 63.2144 25.9697Z"
                fill="white"
              />
              <path
                d="M71.7104 27.0361C71.8481 28.2676 73.0444 29.0761 74.6792 29.0761C76.2456 29.0761 77.3726 28.2675 77.3726 27.1572C77.3726 26.1933 76.6929 25.6162 75.0835 25.2207L73.4741 24.833C71.1939 24.2822 70.1353 23.2158 70.1353 21.4853C70.1353 19.3427 72.0024 17.871 74.6538 17.871C77.2778 17.871 79.0767 19.3427 79.1372 21.4853H77.2612C77.1489 20.246 76.1245 19.498 74.6274 19.498C73.1303 19.498 72.1059 20.2548 72.1059 21.3564C72.1059 22.2343 72.7602 22.7509 74.3608 23.1464L75.729 23.4823C78.2768 24.0849 79.3354 25.1083 79.3354 26.9247C79.3354 29.248 77.4848 30.703 74.5415 30.703C71.7876 30.703 69.9282 29.2821 69.8081 27.036L71.7104 27.0361Z"
                fill="white"
              />
              <path
                d="M83.3462 19.2998V21.4424H85.0679V22.9141H83.3462V27.9053C83.3462 28.6807 83.6909 29.042 84.4477 29.042C84.6521 29.0384 84.8562 29.0241 85.0591 28.999V30.4619C84.7188 30.5255 84.3729 30.5543 84.0268 30.5478C82.1938 30.5478 81.479 29.8593 81.479 28.1035V22.9141H80.1626V21.4424H81.479V19.2998H83.3462Z"
                fill="white"
              />
              <path
                d="M86.065 25.9697C86.065 23.1211 87.7427 21.3311 90.3589 21.3311C92.9839 21.3311 94.6538 23.1211 94.6538 25.9697C94.6538 28.8262 92.9927 30.6084 90.3589 30.6084C87.7261 30.6084 86.065 28.8262 86.065 25.9697ZM92.7603 25.9697C92.7603 24.0156 91.8648 22.8623 90.3589 22.8623C88.853 22.8623 87.9585 24.0244 87.9585 25.9697C87.9585 27.9316 88.853 29.0762 90.3589 29.0762C91.8648 29.0762 92.7603 27.9316 92.7603 25.9697Z"
                fill="white"
              />
              <path
                d="M96.186 21.4424H97.9585V22.9834H98.0015C98.1214 22.5021 98.4034 22.0768 98.8 21.7789C99.1966 21.481 99.6836 21.3287 100.179 21.3476C100.393 21.3469 100.607 21.3702 100.816 21.417V23.1553C100.546 23.0726 100.264 23.0347 99.981 23.043C99.711 23.032 99.4418 23.0796 99.192 23.1825C98.9421 23.2854 98.7175 23.4411 98.5335 23.639C98.3496 23.8369 98.2106 24.0723 98.1262 24.3289C98.0417 24.5856 98.0139 24.8575 98.0444 25.126V30.4961H96.186L96.186 21.4424Z"
                fill="white"
              />
              <path
                d="M109.384 27.8369C109.134 29.4805 107.534 30.6084 105.486 30.6084C102.852 30.6084 101.217 28.8437 101.217 26.0127C101.217 23.1729 102.861 21.3311 105.408 21.3311C107.913 21.3311 109.488 23.0518 109.488 25.7969V26.4336H103.093V26.5459C103.064 26.8791 103.105 27.2148 103.216 27.5306C103.326 27.8464 103.502 28.1352 103.732 28.3778C103.963 28.6203 104.242 28.8111 104.552 28.9374C104.861 29.0637 105.195 29.1226 105.529 29.1103C105.968 29.1515 106.409 29.0498 106.785 28.8203C107.162 28.5909 107.455 28.246 107.62 27.8369L109.384 27.8369ZM103.102 25.1348H107.628C107.645 24.8352 107.6 24.5354 107.495 24.2541C107.39 23.9729 107.229 23.7164 107.02 23.5006C106.812 23.2849 106.561 23.1145 106.283 23.0003C106.006 22.8861 105.708 22.8305 105.408 22.8369C105.105 22.8351 104.805 22.8933 104.525 23.008C104.245 23.1227 103.99 23.2918 103.776 23.5054C103.562 23.7191 103.392 23.973 103.276 24.2527C103.16 24.5323 103.101 24.8321 103.102 25.1348Z"
                fill="white"
              />
              <path
                d="M36.7383 11.2534H37.5362C37.6735 11.2705 37.8129 11.259 37.9456 11.2195C38.0782 11.1801 38.2013 11.1136 38.307 11.0242C38.4127 10.9349 38.4987 10.8246 38.5597 10.7003C38.6206 10.5761 38.6552 10.4405 38.6612 10.3023C38.6612 9.75196 38.2471 9.39649 37.5449 9.39649C37.4042 9.37683 37.261 9.38556 37.1237 9.42217C36.9864 9.45878 36.8579 9.52252 36.7456 9.60964C36.6334 9.69675 36.5398 9.80547 36.4702 9.92938C36.4007 10.0533 36.3567 10.1899 36.3408 10.3311H35.4648C35.4799 10.0748 35.5476 9.82431 35.6637 9.5953C35.7797 9.36629 35.9417 9.16362 36.1395 8.99992C36.3373 8.83621 36.5666 8.71498 36.8133 8.64375C37.06 8.57253 37.3186 8.55285 37.5732 8.58594C38.748 8.58594 39.5752 9.24365 39.5752 10.1904C39.578 10.5076 39.4719 10.8161 39.2746 11.0645C39.0773 11.3129 38.8008 11.4861 38.4912 11.5552V11.6255C38.8582 11.6418 39.2039 11.8027 39.4526 12.0731C39.7013 12.3434 39.8328 12.7013 39.8184 13.0684C39.7912 13.3323 39.7117 13.5881 39.5844 13.8208C39.4571 14.0536 39.2846 14.2585 39.077 14.4238C38.8695 14.589 38.631 14.7111 38.3756 14.783C38.1203 14.8548 37.8531 14.875 37.5899 14.8423C37.3261 14.8783 37.0577 14.8607 36.8008 14.7908C36.5439 14.7208 36.3037 14.5999 36.0946 14.4351C35.8854 14.2703 35.7116 14.0651 35.5835 13.8317C35.4553 13.5984 35.3755 13.3416 35.3487 13.0767H36.2383C36.292 13.6475 36.8164 14.0157 37.5899 14.0157C38.3633 14.0157 38.8682 13.6021 38.8682 13.0191C38.8682 12.4278 38.3926 12.0635 37.5616 12.0635H36.7383V11.2534Z"
                fill="white"
              />
              <path
                d="M40.8291 13.4243C40.8291 12.6138 41.4336 12.1465 42.5039 12.0801L43.7237 12.0098V11.6211C43.7237 11.1455 43.4102 10.877 42.8018 10.877C42.3057 10.877 41.9629 11.0591 41.8633 11.3775H41.003C41.0938 10.604 41.8223 10.1079 42.8438 10.1079C43.9727 10.1079 44.6094 10.6699 44.6094 11.6211V14.6978H43.753V14.065H43.6827C43.5399 14.2919 43.3395 14.477 43.1019 14.6012C42.8643 14.7254 42.598 14.7843 42.3301 14.772C42.1411 14.7916 41.95 14.7715 41.7693 14.7128C41.5885 14.6541 41.422 14.5581 41.2806 14.4312C41.1392 14.3042 41.026 14.149 40.9482 13.9756C40.8704 13.8022 40.8299 13.6144 40.8291 13.4243ZM43.7237 13.0396V12.6631L42.624 12.7334C42.0039 12.7749 41.7226 12.9858 41.7226 13.3828C41.7226 13.7881 42.0742 14.0239 42.5576 14.0239C42.6993 14.0383 42.8424 14.024 42.9784 13.9819C43.1144 13.9398 43.2405 13.8708 43.3493 13.7789C43.4581 13.6871 43.5473 13.5743 43.6116 13.4473C43.6759 13.3203 43.714 13.1816 43.7237 13.0396Z"
                fill="white"
              />
              <path
                d="M46.9678 10.9429V14.6978H46.0791V10.1948H49.044V10.9428L46.9678 10.9429Z"
                fill="white"
              />
              <path
                d="M54.4297 12.4443C54.4297 13.875 53.7061 14.772 52.5694 14.772C52.2903 14.791 52.0117 14.73 51.7662 14.596C51.5207 14.462 51.3185 14.2606 51.1836 14.0156H51.1133V16.1948H50.2246V10.1948H51.0801V10.939H51.1465C51.2839 10.6854 51.4886 10.4748 51.7381 10.3303C51.9877 10.1858 52.2723 10.1131 52.5606 10.1201C53.7061 10.1201 54.4297 11.0132 54.4297 12.4443ZM53.5157 12.4443C53.5157 11.4932 53.0655 10.9185 52.3125 10.9185C51.5645 10.9185 51.0967 11.5054 51.0967 12.4482C51.0967 13.3867 51.5645 13.9741 52.3086 13.9741C53.0655 13.9741 53.5157 13.3994 53.5157 12.4443Z"
                fill="white"
              />
              <path
                d="M55.6133 16.3106V15.5454C55.7095 15.5576 55.8064 15.5632 55.9033 15.562C56.0927 15.576 56.2805 15.5197 56.4308 15.4037C56.5811 15.2877 56.6833 15.1203 56.7178 14.9336L56.7842 14.7144L55.1543 10.1948H56.1221L57.251 13.7715H57.3213L58.4463 10.1948H59.3887L57.71 14.8838C57.3252 15.9795 56.8995 16.3311 55.9815 16.3311C55.9073 16.3311 55.6963 16.3228 55.6133 16.3106Z"
                fill="white"
              />
              <path
                d="M60.0811 13.4199H60.9659C61.0528 13.8169 61.4249 14.0239 61.9453 14.0239C62.5371 14.0239 62.9004 13.7632 62.9004 13.3579C62.9004 12.9238 62.5743 12.7168 61.9453 12.7168H61.2715V12.0264H61.9336C62.4707 12.0264 62.7442 11.8198 62.7442 11.4434C62.7442 11.1128 62.4795 10.8604 61.9336 10.8604C61.4366 10.8604 61.0733 11.0713 61.0156 11.4268H60.1592C60.2232 11.0183 60.445 10.6513 60.7768 10.4047C61.1087 10.158 61.524 10.0515 61.9336 10.1079C63.0039 10.1079 63.6162 10.5918 63.6162 11.3526C63.6212 11.5644 63.5534 11.7716 63.424 11.9394C63.2946 12.1072 63.1115 12.2254 62.9053 12.2744V12.3448C63.1507 12.3764 63.376 12.4971 63.5384 12.6838C63.7008 12.8706 63.7889 13.1105 63.7862 13.3579C63.7862 14.2139 63.0576 14.7803 61.9453 14.7803C60.9619 14.7803 60.1631 14.3296 60.0811 13.4199Z"
                fill="white"
              />
              <path
                d="M65.0659 14.6978V10.1948H65.9468V13.292H66.0171L68.0923 10.1948H68.9732V14.6978H68.0923V11.584H68.0259L65.9468 14.6978L65.0659 14.6978Z"
                fill="white"
              />
              <path
                d="M73.8257 10.9429H72.4077V14.6978H71.5181V10.9429H70.1001V10.1949H73.8257V10.9429Z"
                fill="white"
              />
              <path
                d="M78.6128 13.4819C78.4917 13.8934 78.2299 14.2493 77.8731 14.4875C77.5163 14.7256 77.0871 14.8308 76.6607 14.7847C76.364 14.7925 76.0692 14.7357 75.7967 14.6181C75.5242 14.5006 75.2805 14.3252 75.0826 14.104C74.8847 13.8829 74.7373 13.6213 74.6506 13.3375C74.5639 13.0537 74.54 12.7544 74.5806 12.4604C74.5411 12.1655 74.5654 11.8656 74.6519 11.5809C74.7384 11.2963 74.885 11.0335 75.0819 10.8104C75.2787 10.5874 75.5212 10.4092 75.7929 10.288C76.0647 10.1668 76.3592 10.1054 76.6567 10.1079C77.9097 10.1079 78.6665 10.9639 78.6665 12.3779V12.688H75.4868V12.7378C75.4728 12.9031 75.4934 13.0695 75.5475 13.2263C75.6015 13.3831 75.6878 13.5269 75.8007 13.6484C75.9137 13.7699 76.0508 13.8664 76.2033 13.9317C76.3557 13.9971 76.5202 14.0298 76.6861 14.0278C76.8985 14.0532 77.1137 14.0149 77.3042 13.9177C77.4948 13.8204 77.6522 13.6688 77.7564 13.4819L78.6128 13.4819ZM75.4868 12.0308H77.7602C77.7715 11.8797 77.751 11.728 77.7001 11.5853C77.6492 11.4426 77.5691 11.3122 77.4648 11.2023C77.3605 11.0925 77.2344 11.0057 77.0945 10.9474C76.9547 10.8892 76.8043 10.8609 76.6528 10.8643C76.4991 10.8622 76.3465 10.8909 76.204 10.9488C76.0616 11.0067 75.9322 11.0925 75.8235 11.2013C75.7148 11.31 75.629 11.4395 75.5712 11.5819C75.5134 11.7244 75.4846 11.877 75.4868 12.0308Z"
                fill="white"
              />
              <path
                d="M82.3804 10.1948H84.4107C85.2915 10.1948 85.8081 10.6411 85.8081 11.3398C85.8095 11.5615 85.7347 11.777 85.5962 11.9501C85.4577 12.1233 85.264 12.2436 85.0474 12.291V12.3613C85.3104 12.3779 85.5569 12.4949 85.7361 12.6881C85.9153 12.8813 86.0135 13.1359 86.0103 13.3994C86.0103 14.185 85.4195 14.6977 84.4312 14.6977H82.3804V10.1948ZM83.2613 10.877V12.043H84.1421C84.6792 12.043 84.9361 11.8364 84.9361 11.4556C84.9361 11.1045 84.7124 10.8769 84.2408 10.8769L83.2613 10.877ZM83.2613 12.6963V14.0156H84.315C84.8404 14.0156 85.1217 13.7837 85.1217 13.3579C85.1217 12.9072 84.8033 12.6963 84.2037 12.6963L83.2613 12.6963Z"
                fill="white"
              />
            </svg>
          </a>
          <a
            href="https://apps.rustore.ru/app/com.landlordmobileapp"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="40"
              viewBox="0 0 120 40"
              fill="none"
            >
              <path
                d="M109.918 0.00013H9.51592C9.14994 0.00013 8.78835 0.00013 8.42334 0.00213C8.11779 0.00413 7.81468 0.00994 7.5062 0.01483C6.83603 0.0227318 6.16745 0.0818063 5.50624 0.19154C4.84597 0.303663 4.20638 0.515046 3.6091 0.81854C3.01256 1.1246 2.46748 1.5223 1.99364 1.9972C1.51731 2.47077 1.12025 3.01815 0.817738 3.61829C0.514386 4.21724 0.304041 4.85907 0.193968 5.52161C0.0828476 6.18332 0.0230529 6.85265 0.0151202 7.52361C0.00585845 7.83021 0.00488038 8.13783 0 8.44447V31.5587C0.00488038 31.8692 0.00585845 32.17 0.0151202 32.4806C0.0230554 33.1516 0.08285 33.8209 0.193968 34.4825C0.303737 35.1455 0.514094 35.7877 0.817738 36.3868C1.12012 36.985 1.51723 37.5302 1.99364 38.0011C2.46568 38.4781 3.01111 38.8761 3.6091 39.1798C4.20637 39.4841 4.84589 39.6968 5.50624 39.8106C6.16756 39.9195 6.83608 39.9786 7.5062 39.9874C7.81468 39.9942 8.11779 39.9981 8.42334 39.9981C8.78834 40.0001 9.14996 40.0001 9.51592 40.0001H109.918C110.277 40.0001 110.641 40.0001 111 39.9981C111.304 39.9981 111.616 39.9942 111.92 39.9874C112.589 39.9791 113.256 39.92 113.916 39.8106C114.579 39.696 115.22 39.4834 115.82 39.1798C116.418 38.8759 116.963 38.478 117.435 38.0011C117.91 37.5284 118.308 36.9836 118.614 36.3868C118.915 35.7872 119.123 35.1451 119.232 34.4825C119.343 33.8208 119.405 33.1516 119.417 32.4806C119.421 32.17 119.421 31.8692 119.421 31.5587C119.429 31.1954 119.429 30.8341 119.429 30.4649V9.53626C119.429 9.17005 119.429 8.80677 119.421 8.44447C119.421 8.13783 119.421 7.83021 119.417 7.52357C119.405 6.85255 119.343 6.18337 119.232 5.52157C119.123 4.85941 118.915 4.21763 118.614 3.61825C117.998 2.41533 117.021 1.43616 115.82 0.81845C115.22 0.515697 114.578 0.30437 113.916 0.19145C113.256 0.0812328 112.589 0.0221378 111.92 0.01469C111.616 0.00981 111.304 0.00395 111 0.002C110.641 0 110.277 0.00013 109.918 0.00013Z"
                fill="#A6A6A6"
              />
              <path
                d="M8.42821 39.125C8.12413 39.125 7.8274 39.1211 7.5257 39.1143C6.90071 39.1061 6.27719 39.0516 5.66024 38.9512C5.08496 38.8519 4.52768 38.6673 4.00676 38.4033C3.49062 38.1415 3.01986 37.7983 2.61251 37.3867C2.19926 36.98 1.85522 36.5082 1.59401 35.9902C1.32992 35.4688 1.14715 34.9099 1.05208 34.333C0.949402 33.7131 0.893852 33.0863 0.885903 32.458C0.879576 32.2471 0.871292 31.5449 0.871292 31.5449V8.44434C0.871292 8.44434 0.880115 7.75293 0.885953 7.5498C0.893564 6.92248 0.948792 6.29665 1.05116 5.67773C1.14641 5.09925 1.32932 4.53875 1.59355 4.01563C1.8538 3.49794 2.19593 3.02586 2.60669 2.61768C3.01698 2.20562 3.48925 1.8606 4.00627 1.59521C4.52599 1.33209 5.08222 1.14873 5.65634 1.05127C6.27531 0.949836 6.901 0.894996 7.52816 0.88721L8.4287 0.875H110.995L111.906 0.8877C112.528 0.895099 113.148 0.94945 113.761 1.05029C114.341 1.14898 114.903 1.33362 115.429 1.59814C116.464 2.13299 117.307 2.97916 117.839 4.01807C118.099 4.53758 118.279 5.09351 118.373 5.66699C118.477 6.29099 118.535 6.92174 118.547 7.5542C118.549 7.8374 118.549 8.1416 118.549 8.44434C118.557 8.81934 118.557 9.17627 118.557 9.53613V30.4648C118.557 30.8281 118.557 31.1826 118.549 31.54C118.549 31.8652 118.549 32.1631 118.546 32.4697C118.534 33.0909 118.477 33.7104 118.375 34.3232C118.282 34.9043 118.1 35.4675 117.836 35.9932C117.573 36.5056 117.231 36.9733 116.822 37.3789C116.415 37.7927 115.943 38.1379 115.426 38.4014C114.902 38.6674 114.34 38.8527 113.761 38.9512C113.144 39.0522 112.521 39.1067 111.896 39.1143C111.603 39.1211 111.297 39.125 111 39.125L109.918 39.127L8.42821 39.125Z"
                fill="black"
              />
              <path
                d="M24.7201 20.3007C24.7309 19.466 24.9521 18.6476 25.3633 17.9217C25.7746 17.1957 26.3624 16.5858 27.0721 16.1486C26.6212 15.5035 26.0264 14.9725 25.335 14.598C24.6435 14.2234 23.8744 14.0156 23.0887 13.9909C21.4128 13.8147 19.7881 14.9958 18.934 14.9958C18.0635 14.9958 16.7486 14.0084 15.3326 14.0376C14.4168 14.0673 13.5242 14.3341 12.7419 14.8122C11.9595 15.2902 11.3141 15.9632 10.8685 16.7655C8.93833 20.1139 10.3781 25.035 12.227 27.7416C13.1521 29.0669 14.2333 30.5474 15.6479 30.4949C17.0323 30.4374 17.5493 29.6104 19.2203 29.6104C20.8758 29.6104 21.3609 30.4949 22.8042 30.4615C24.2897 30.4373 25.2256 29.1303 26.1182 27.7924C26.7829 26.848 27.2943 25.8043 27.6336 24.6999C26.7706 24.3341 26.0342 23.7219 25.5161 22.9396C24.998 22.1574 24.7211 21.2395 24.7201 20.3007Z"
                fill="white"
              />
              <path
                d="M21.9939 12.2109C22.8038 11.2367 23.2028 9.98451 23.1062 8.72028C21.8688 8.8505 20.7258 9.44306 19.9049 10.3799C19.5036 10.8376 19.1962 11.37 19.0003 11.9468C18.8045 12.5235 18.724 13.1333 18.7635 13.7413C19.3824 13.7477 19.9947 13.6132 20.5543 13.3482C21.1138 13.0831 21.606 12.6942 21.9939 12.2109Z"
                fill="white"
              />
              <path
                d="M65.8551 27.0361C65.9925 28.2676 67.1864 29.0761 68.818 29.0761C70.3813 29.0761 71.506 28.2675 71.506 27.1572C71.506 26.1933 70.8277 25.6162 69.2215 25.2207L67.6153 24.833C65.3395 24.2822 64.283 23.2158 64.283 21.4853C64.283 19.3427 66.1465 17.871 68.7926 17.871C71.4115 17.871 73.2068 19.3427 73.2672 21.4853H71.3949C71.2828 20.246 70.2604 19.498 68.7663 19.498C67.2722 19.498 66.2498 20.2548 66.2498 21.3564C66.2498 22.2343 66.9028 22.7509 68.5002 23.1464L69.8657 23.4823C72.4085 24.0849 73.465 25.1083 73.465 26.9247C73.465 29.248 71.6181 30.703 68.6805 30.703C65.932 30.703 64.0763 29.2821 63.9564 27.036L65.8551 27.0361Z"
                fill="white"
              />
              <path
                d="M77.4679 19.2998V21.4424H79.1862V22.9141H77.4679V27.9053C77.4679 28.6807 77.8119 29.042 78.5673 29.042C78.7713 29.0384 78.9749 29.0241 79.1774 28.999V30.4619C78.8378 30.5255 78.4926 30.5543 78.1472 30.5478C76.3178 30.5478 75.6044 29.8593 75.6044 28.1035V22.9141H74.2906V21.4424H75.6044V19.2998H77.4679Z"
                fill="white"
              />
              <path
                d="M80.1813 25.9697C80.1813 23.1211 81.8558 21.3311 84.4668 21.3311C87.0867 21.3311 88.7533 23.1211 88.7533 25.9697C88.7533 28.8262 87.0954 30.6084 84.4668 30.6084C81.8392 30.6084 80.1813 28.8262 80.1813 25.9697ZM86.8635 25.9697C86.8635 24.0156 85.9697 22.8623 84.4668 22.8623C82.9639 22.8623 82.0712 24.0244 82.0712 25.9697C82.0712 27.9316 82.9639 29.0762 84.4668 29.0762C85.9697 29.0762 86.8635 27.9316 86.8635 25.9697Z"
                fill="white"
              />
              <path
                d="M90.2825 21.4424H92.0515V22.9834H92.0944C92.2141 22.5021 92.4954 22.0768 92.8913 21.7789C93.2871 21.481 93.7732 21.3287 94.2678 21.3476C94.4816 21.3469 94.6947 21.3702 94.9033 21.417V23.1553C94.6334 23.0726 94.352 23.0347 94.0699 23.043C93.8005 23.032 93.5319 23.0796 93.2825 23.1825C93.0332 23.2854 92.809 23.4411 92.6254 23.639C92.4417 23.8369 92.3031 24.0723 92.2188 24.3289C92.1345 24.5856 92.1067 24.8575 92.1372 25.126V30.4961H90.2825L90.2825 21.4424Z"
                fill="white"
              />
              <path
                d="M103.455 27.8369C103.205 29.4805 101.608 30.6084 99.564 30.6084C96.9354 30.6084 95.3038 28.8437 95.3038 26.0127C95.3038 23.1729 96.9441 21.3311 99.486 21.3311C101.986 21.3311 103.558 23.0518 103.558 25.7969V26.4336H97.1761V26.5459C97.1467 26.8791 97.1883 27.2148 97.2983 27.5306C97.4082 27.8464 97.584 28.1352 97.8139 28.3778C98.0438 28.6203 98.3226 28.8111 98.6317 28.9374C98.9408 29.0637 99.2733 29.1226 99.6069 29.1103C100.045 29.1515 100.485 29.0498 100.861 28.8203C101.237 28.5909 101.529 28.246 101.694 27.8369L103.455 27.8369ZM97.1849 25.1348H101.702C101.719 24.8352 101.674 24.5354 101.569 24.2541C101.465 23.9729 101.303 23.7164 101.095 23.5006C100.887 23.2849 100.637 23.1145 100.36 23.0003C100.083 22.8861 99.7854 22.8305 99.486 22.8369C99.184 22.8351 98.8846 22.8933 98.6051 23.008C98.3256 23.1227 98.0715 23.2918 97.8576 23.5054C97.6437 23.7191 97.4741 23.973 97.3586 24.2527C97.2431 24.5323 97.1841 24.8321 97.1849 25.1348Z"
                fill="white"
              />
              <path
                d="M44.0946 11.2534H44.8909C45.0279 11.2705 45.1671 11.259 45.2995 11.2195C45.4319 11.1801 45.5547 11.1136 45.6602 11.0242C45.7657 10.9349 45.8515 10.8246 45.9124 10.7003C45.9732 10.5761 46.0077 10.4405 46.0136 10.3023C46.0136 9.75196 45.6004 9.39649 44.8996 9.39649C44.7592 9.37683 44.6162 9.38556 44.4792 9.42217C44.3422 9.45878 44.2139 9.52252 44.1019 9.60964C43.9899 9.69675 43.8964 9.80547 43.827 9.92938C43.7576 10.0533 43.7137 10.1899 43.6979 10.3311H42.8236C42.8387 10.0748 42.9062 9.82431 43.022 9.5953C43.1379 9.36629 43.2995 9.16362 43.4969 8.99992C43.6943 8.83621 43.9232 8.71498 44.1694 8.64375C44.4156 8.57253 44.6738 8.55285 44.9279 8.58594C46.1004 8.58594 46.9259 9.24365 46.9259 10.1904C46.9287 10.5076 46.8228 10.8161 46.6259 11.0645C46.429 11.3129 46.153 11.4861 45.8441 11.5552V11.6255C46.2103 11.6418 46.5553 11.8027 46.8035 12.0731C47.0517 12.3434 47.183 12.7013 47.1686 13.0684C47.1415 13.3323 47.0621 13.5881 46.935 13.8208C46.808 14.0536 46.6358 14.2585 46.4287 14.4238C46.2215 14.589 45.9836 14.7111 45.7287 14.783C45.4738 14.8548 45.2072 14.875 44.9445 14.8423C44.6812 14.8783 44.4134 14.8607 44.157 14.7908C43.9006 14.7208 43.6609 14.5999 43.4521 14.4351C43.2434 14.2703 43.0699 14.0651 42.942 13.8317C42.8141 13.5984 42.7344 13.3416 42.7077 13.0767H43.5956C43.6492 13.6475 44.1726 14.0157 44.9445 14.0157C45.7164 14.0157 46.2203 13.6021 46.2203 13.0191C46.2203 12.4278 45.7456 12.0635 44.9162 12.0635H44.0946V11.2534Z"
                fill="white"
              />
              <path
                d="M48.1773 13.4243C48.1773 12.6138 48.7806 12.1465 49.8488 12.0801L51.0662 12.0098V11.6211C51.0662 11.1455 50.7533 10.877 50.1461 10.877C49.651 10.877 49.3089 11.0591 49.2095 11.3775H48.3508C48.4415 10.604 49.1686 10.1079 50.188 10.1079C51.3147 10.1079 51.9502 10.6699 51.9502 11.6211V14.6978H51.0954V14.065H51.0252C50.8828 14.2919 50.6828 14.477 50.4456 14.6012C50.2085 14.7254 49.9427 14.7843 49.6754 14.772C49.4867 14.7916 49.296 14.7715 49.1156 14.7128C48.9352 14.6541 48.7691 14.5581 48.628 14.4312C48.4868 14.3042 48.3738 14.149 48.2962 13.9756C48.2186 13.8022 48.1781 13.6144 48.1773 13.4243ZM51.0662 13.0396V12.6631L49.9687 12.7334C49.3498 12.7749 49.0691 12.9858 49.0691 13.3828C49.0691 13.7881 49.42 14.0239 49.9024 14.0239C50.0438 14.0383 50.1866 14.024 50.3224 13.9819C50.4581 13.9398 50.584 13.8708 50.6926 13.7789C50.8012 13.6871 50.8902 13.5743 50.9544 13.4473C51.0186 13.3203 51.0566 13.1816 51.0662 13.0396Z"
                fill="white"
              />
              <path
                d="M54.3039 10.9429V14.6978H53.417V10.1948H56.3761V10.9428L54.3039 10.9429Z"
                fill="white"
              />
              <path
                d="M61.7512 12.4443C61.7512 13.875 61.029 14.772 59.8945 14.772C59.616 14.791 59.3379 14.73 59.0928 14.596C58.8478 14.462 58.6461 14.2606 58.5115 14.0156H58.4413V16.1948H57.5544V10.1948H58.4082V10.939H58.4744C58.6115 10.6854 58.8158 10.4748 59.0649 10.3303C59.3139 10.1858 59.598 10.1131 59.8857 10.1201C61.029 10.1201 61.7512 11.0132 61.7512 12.4443ZM60.8389 12.4443C60.8389 11.4932 60.3896 10.9185 59.6382 10.9185C58.8916 10.9185 58.4247 11.5054 58.4247 12.4482C58.4247 13.3867 58.8916 13.9741 59.6343 13.9741C60.3896 13.9741 60.8389 13.3994 60.8389 12.4443Z"
                fill="white"
              />
              <path
                d="M62.9324 16.3106V15.5454C63.0284 15.5576 63.1251 15.5632 63.2219 15.562C63.4108 15.576 63.5983 15.5197 63.7483 15.4037C63.8983 15.2877 64.0003 15.1203 64.0347 14.9336L64.101 14.7144L62.4743 10.1948H63.4402L64.5669 13.7715H64.6371L65.7599 10.1948H66.7004L65.025 14.8838C64.641 15.9795 64.216 16.3311 63.2999 16.3311C63.2258 16.3311 63.0153 16.3228 62.9324 16.3106Z"
                fill="white"
              />
              <path
                d="M67.3914 13.4199H68.2744C68.3612 13.8169 68.7325 14.0239 69.252 14.0239C69.8426 14.0239 70.2052 13.7632 70.2052 13.3579C70.2052 12.9238 69.8797 12.7168 69.252 12.7168H68.5795V12.0264H69.2403C69.7763 12.0264 70.0493 11.8198 70.0493 11.4434C70.0493 11.1128 69.7851 10.8604 69.2403 10.8604C68.7442 10.8604 68.3816 11.0713 68.3241 11.4268H67.4694C67.5333 11.0183 67.7546 10.6513 68.0858 10.4047C68.417 10.158 68.8315 10.0515 69.2403 10.1079C70.3085 10.1079 70.9196 10.5918 70.9196 11.3526C70.9246 11.5644 70.8568 11.7716 70.7277 11.9394C70.5985 12.1072 70.4158 12.2254 70.2101 12.2744V12.3448C70.455 12.3764 70.6799 12.4971 70.8419 12.6838C71.004 12.8706 71.092 13.1105 71.0892 13.3579C71.0892 14.2139 70.3621 14.7803 69.252 14.7803C68.2705 14.7803 67.4733 14.3296 67.3914 13.4199Z"
                fill="white"
              />
              <path
                d="M72.3665 14.6978V10.1948H73.2456V13.292H73.3158L75.3869 10.1948H76.266V14.6978H75.3869V11.584H75.3206L73.2456 14.6978L72.3665 14.6978Z"
                fill="white"
              />
              <path
                d="M81.109 10.9429H79.6938V14.6978H78.8059V10.9429H77.3907V10.1949H81.109V10.9429Z"
                fill="white"
              />
              <path
                d="M85.8867 13.4819C85.7658 13.8934 85.5045 14.2493 85.1484 14.4875C84.7923 14.7256 84.364 14.8308 83.9384 14.7847C83.6423 14.7925 83.3481 14.7357 83.0761 14.6181C82.8041 14.5006 82.5609 14.3252 82.3634 14.104C82.1659 13.8829 82.0188 13.6213 81.9323 13.3375C81.8457 13.0537 81.8219 12.7544 81.8624 12.4604C81.823 12.1655 81.8472 11.8656 81.9335 11.5809C82.0199 11.2963 82.1662 11.0335 82.3627 10.8104C82.5592 10.5874 82.8012 10.4092 83.0724 10.288C83.3435 10.1668 83.6375 10.1054 83.9345 10.1079C85.1849 10.1079 85.9403 10.9639 85.9403 12.3779V12.688H82.7669V12.7378C82.7528 12.9031 82.7734 13.0695 82.8274 13.2263C82.8813 13.3831 82.9674 13.5269 83.0802 13.6484C83.1929 13.7699 83.3297 13.8664 83.4819 13.9317C83.634 13.9971 83.7982 14.0298 83.9637 14.0278C84.1757 14.0532 84.3905 14.0149 84.5807 13.9177C84.7709 13.8204 84.928 13.6688 85.0319 13.4819L85.8867 13.4819ZM82.7668 12.0308H85.0358C85.047 11.8797 85.0266 11.728 84.9758 11.5853C84.925 11.4426 84.845 11.3122 84.7409 11.2023C84.6368 11.0925 84.511 11.0057 84.3714 10.9474C84.2319 10.8892 84.0817 10.8609 83.9306 10.8643C83.7771 10.8622 83.6248 10.8909 83.4826 10.9488C83.3405 11.0067 83.2113 11.0925 83.1029 11.2013C82.9944 11.31 82.9088 11.4395 82.851 11.5819C82.7933 11.7244 82.7647 11.877 82.7668 12.0308Z"
                fill="white"
              />
              <path
                d="M89.6468 10.1948H91.6731C92.5522 10.1948 93.0678 10.6411 93.0678 11.3398C93.0692 11.5615 92.9945 11.777 92.8563 11.9501C92.7181 12.1233 92.5247 12.2436 92.3086 12.291V12.3613C92.571 12.3779 92.8171 12.4949 92.9959 12.6881C93.1748 12.8813 93.2727 13.1359 93.2696 13.3994C93.2696 14.185 92.6799 14.6977 91.6936 14.6977H89.6468V10.1948ZM90.526 10.877V12.043H91.4051C91.9412 12.043 92.1975 11.8364 92.1975 11.4556C92.1975 11.1045 91.9743 10.8769 91.5035 10.8769L90.526 10.877ZM90.526 12.6963V14.0156H91.5776C92.102 14.0156 92.3827 13.7837 92.3827 13.3579C92.3827 12.9072 92.065 12.6963 91.4665 12.6963L90.526 12.6963Z"
                fill="white"
              />
              <path
                d="M42.7869 30.6V18.2364H47.1938C48.1517 18.2364 48.9466 18.4014 49.5784 18.7314C50.2143 19.0614 50.6892 19.5182 51.0031 20.1018C51.3171 20.6813 51.474 21.3514 51.474 22.1121C51.474 22.8687 51.3151 23.5348 50.9971 24.1103C50.6832 24.6818 50.2083 25.1265 49.5724 25.4445C48.9405 25.7624 48.1457 25.9214 47.1878 25.9214H43.8494V24.3156H47.0188C47.6225 24.3156 48.1135 24.229 48.4918 24.056C48.8741 23.8829 49.1538 23.6314 49.3309 23.3014C49.508 22.9713 49.5965 22.5749 49.5965 22.1121C49.5965 21.6452 49.506 21.2407 49.3249 20.8987C49.1478 20.5566 48.8681 20.295 48.4857 20.1139C48.1074 19.9287 47.6104 19.8362 46.9946 19.8362H44.6523V30.6H42.7869ZM48.8902 25.0219L51.9449 30.6H49.8199L46.8256 25.0219H48.8902ZM59.473 26.7545V21.3273H61.284V30.6H59.5092V28.9942H59.4126C59.1993 29.4892 58.8572 29.9017 58.3863 30.2318C57.9195 30.5577 57.3379 30.7207 56.6416 30.7207C56.046 30.7207 55.5188 30.5899 55.06 30.3283C54.6052 30.0627 54.247 29.6703 53.9854 29.1511C53.7278 28.632 53.599 27.99 53.599 27.2254V21.3273H55.4041V27.008C55.4041 27.6399 55.5791 28.143 55.9293 28.5173C56.2794 28.8916 56.7342 29.0787 57.2936 29.0787C57.6317 29.0787 57.9678 28.9942 58.3018 28.8251C58.6399 28.6561 58.9196 28.4006 59.1409 28.0585C59.3663 27.7164 59.477 27.2817 59.473 26.7545Z"
                fill="white"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.1657 33.7143C14.9593 33.7143 11.8561 33.7143 9.92808 31.7862C8 29.8581 8 26.7549 8 20.5485L8 19.4514C8 13.245 8 10.1418 9.92807 8.21369C11.8561 6.28572 14.9593 6.28572 21.1657 6.28572L22.2629 6.28572C28.4692 6.28572 31.5724 6.28572 33.5005 8.21368C35.4286 10.1417 35.4286 13.245 35.4286 19.4514V20.5485C35.4286 26.7549 35.4286 29.8581 33.5005 31.7862C31.5724 33.7143 28.4692 33.7143 22.2629 33.7143H21.1657Z"
                fill="#2B71F6"
              />
              <path
                d="M24.0798 23.2119C23.3611 23.0285 22.8571 22.3717 22.8571 21.6184V12.7436C22.8571 11.888 23.6486 11.2604 24.4649 11.4688L29.6345 12.7881C30.3532 12.9715 30.8571 13.6283 30.8571 14.3816V23.2564C30.8571 24.112 30.0657 24.7396 29.2494 24.5312L24.0798 23.2119Z"
                fill="white"
              />
              <path
                d="M13.7941 27.2119C13.0754 27.0285 12.5714 26.3717 12.5714 25.6184V16.7436C12.5714 15.888 13.3628 15.2604 14.1792 15.4688L19.3488 16.7881C20.0675 16.9715 20.5714 17.6283 20.5714 18.3816V27.2564C20.5714 28.112 19.78 28.7395 18.9637 28.5312L13.7941 27.2119Z"
                fill="white"
              />
              <path
                d="M18.9369 25.4385C18.2182 25.2471 17.7143 24.5618 17.7143 23.7757V14.5151C17.7143 13.6222 18.5057 12.9674 19.322 13.1848L24.4916 14.5615C25.2104 14.7529 25.7143 15.4382 25.7143 16.2243V25.4849C25.7143 26.3777 24.9229 27.0326 24.1065 26.8152L18.9369 25.4385Z"
                fill="white"
              />
              <path
                d="M22.2857 26.2857C22.0431 26.2266 21.8705 26.0212 21.862 25.7817L21.621 18.9751C21.5513 18.0742 20.8527 17.3577 20.1228 17.1467C20.0818 17.1349 20.0381 17.1509 20.0138 17.1846C19.9892 17.2189 19.9987 17.2664 20.0331 17.2919C20.2133 17.4254 20.7094 17.8614 20.7094 18.6133L20.708 25.903L22.2857 26.2857Z"
                fill="#2B71F6"
              />
              <path
                d="M27.4286 24C27.1862 23.9419 27.0133 23.7371 27.0048 23.4979L26.7638 16.6899C26.6941 15.7888 25.9956 15.0721 25.2657 14.861C25.2247 14.8492 25.181 14.8652 25.1567 14.8989C25.132 14.9332 25.1415 14.9807 25.1759 15.0062C25.3562 15.1398 25.8523 15.5759 25.8523 16.328L25.8508 23.6222L27.4286 24Z"
                fill="#2B71F6"
              />
            </svg>
          </a>
        </div>
        <div className="bottom">
          ЛЕНДЛОРД ™<span>1994-2024</span>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
